import React, { memo, useContext, useEffect } from "react";

import { useState } from "react";

// import ConfirmacionDialog from "../../Dialog/ConfirmacionDialog";

import ConfirmacionDialog from "../../../../components/Extra/ConfirmacionDialog";

import { withRouter } from "react-router";
import ButtonsAction from "./ButtonsAction";
import AuthContext from "../../../../Context/Auth/AuthContext";
import { tienePermisoRuta } from "../../../../config/validaciones";
import MenuContext from "../../../../Context/Menu/MenuContext";
import AlertaContext from "../../../../Context/Alerta/AlertaContext";
import { permisoDenagado, trimPropsItem } from "../../../../config/const";

// MATERIAL
import { makeStyles } from "@mui/styles";
import { Collapse, TableCell, TableRow } from "@mui/material";
const useRowStyles = makeStyles((theme) => ({
  root: {
    "& > *": {
      borderBottom: ".5px solid #ccc",
    },
  },
  celda: {
    fontSize: ".7rem !important",
  },
  hover: {
    "&:hover": {
      opacity: ".7",
      cursor: "pointer",
    },
  },
  active: {
    borderBottom: "5px solid #ccc",
    backgroundColor: "#354046",
  },
  color: {
    color: "white",
    margin: "0px",
    padding: "2px",
  },
}));

const Row = (props) => {
  const {
    arrayExport,
    setarrayExport,
    row,
    index,
    campoExport,
    seteditar,
    setagregar,
    changeObjetoActivoState,
    socket,
  } = props;
  const { rows: rowsMenu } = useContext(MenuContext);
  const { mostrarAlerta } = useContext(AlertaContext);
  const { usuario } = useContext(AuthContext);
  const [celComprimida, setcelComprimida] = useState({});
  const classes = useRowStyles();

  const handleEliminar = async (_id) => {
    if (
      !tienePermisoRuta(
        rowsMenu,
        props.location.pathname,
        usuario.rol,
        "eliminar"
      )
    ) {
      return mostrarAlerta(permisoDenagado, "error");
    }
    try {
      socket.current.emit(
        "client:eliminarData",
        trimPropsItem({
          tabla: "caja",
          rucempresa: usuario.rucempresa,
          rol: usuario.rol,
          cajaId: row.cajaId,
          cajaFecha: row.cajaFecha,
          cajaUser: row.cajaUser,
        })
      );
    } catch (error) {}
  };
  const funcionEdit = () => {
    if (
      !tienePermisoRuta(
        rowsMenu,
        props.location.pathname,
        usuario.rol,
        "editar"
      )
    ) {
      return mostrarAlerta(permisoDenagado, "error");
    }
    changeObjetoActivoState(row);
    seteditar(true);
    setagregar(true);
  };

  useEffect(() => {}, []);
  const [openConfirmDialog, setopenConfirmDialog] = useState(false);
  return (
    <React.Fragment>
      <ConfirmacionDialog
        open={openConfirmDialog}
        setopen={setopenConfirmDialog}
        titulo={`¿Esta seguro de eliminar este registro: ${row.cajaNumero}?`}
        contenido={`La categoría ${row.cajaNumero} será eliminada, no podrá ser recuperada.`}
        _id={row._id}
        funcion={handleEliminar}
      />

      <TableRow className={classes.root} selected={index % 2 === 0}>
        {/* boton */}
        <ButtonsAction
          arrayExport={arrayExport}
          setarrayExport={setarrayExport}
          row={row}
          item={row}
          setopenConfirmDialog={setopenConfirmDialog}
          funcionEdit={funcionEdit}
          ocultarEditar={false}
          ocultarDelete={false}
          mostrarEstado={true}
          campoExport={campoExport}
          campoEstado="cajaEstado"
        />
        <TableCell
          padding="none"
          size="small"
          align="center"
          className={classes.celda}
        >
          {row.cajaNumero}
        </TableCell>
        <TableCell
          padding="none"
          size="small"
          align="center"
          className={classes.celda}
        >
          {row.cajaFecha.substr(0, 10)}
        </TableCell>{" "}
        <TableCell
          padding="none"
          size="small"
          align="center"
          className={`${classes.celda} ${classes.hover}`}
          onClick={() =>
            setcelComprimida({
              ...celComprimida,
              cajaDetalle:
                row.cajaDetalle.length > 30
                  ? !celComprimida["cajaDetalle"]
                  : celComprimida["cajaDetalle"],
            })
          }
        >
          {!celComprimida["cajaDetalle"] ? (
            <>
              {row.cajaDetalle.length > 30
                ? `${row.cajaDetalle.substr(0, 30)}...`
                : `${row.cajaDetalle.substr(0, 30)}`}
            </>
          ) : null}
          <Collapse
            in={celComprimida["cajaDetalle"]}
            timeout="auto"
            unmountOnExit
          >
            {row.cajaDetalle}
          </Collapse>
        </TableCell>
        <TableCell
          padding="none"
          size="small"
          align="center"
          className={classes.celda}
          sx={{
            textDecoration: row.cajaEstado === "ANULADA" ? "line-through" : "none",
            color: row.cajaEstado === "ANULADA" ? "red" : "inherit",
          }}
        >
          {row.cajaIngreso}
        </TableCell>
        <TableCell
          padding="none"
          size="small"
          align="center"
          className={classes.celda}
          sx={{
            textDecoration: row.cajaEstado === "ANULADA" ? "line-through" : "none",
            color: row.cajaEstado === "ANULADA" ? "red" : "inherit",
          }}
        >
          {row.cajaEgreso}
        </TableCell>
        <TableCell
          padding="none"
          size="small"
          align="center"
          className={classes.celda}
          sx={{
            textDecoration: row.cajaEstado === "ANULADA" ? "line-through" : "none",
            color: row.cajaEstado === "ANULADA" ? "red" : "inherit",
          }}
        >
          {row.cajaCredito}
        </TableCell>
        <TableCell
          padding="none"
          size="small"
          align="center"
          className={classes.celda}
          sx={{
            textDecoration: row.cajaEstado === "ANULADA" ? "line-through" : "none",
            color: row.cajaEstado === "ANULADA" ? "red" : "inherit",
          }}
        >
          {row.cajaMonto}
        </TableCell>
        <TableCell
          padding="none"
          size="small"
          align="center"
          className={classes.celda}
        >
          {row.cajaTipoCaja}
        </TableCell>
        <TableCell
          padding="none"
          size="small"
          align="center"
          className={classes.celda}
        >
          {row.cajaTipoDocumento}
        </TableCell>
        <TableCell
          padding="none"
          size="small"
          align="center"
          className={classes.celda}
        >
          {row.cajaProcedencia}
        </TableCell>
        <TableCell
          padding="none"
          size="small"
          align="center"
          className={classes.celda}
        >
          {row.cajaAutorizacion}
        </TableCell>
        <TableCell
          padding="none"
          size="small"
          align="center"
          className={classes.celda}
        >
          {row.cajaUser}
        </TableCell>
        <TableCell
          padding="none"
          size="small"
          align="center"
          className={classes.celda}
        >
          {new Date(row.cajaFecReg).toLocaleString("es-ES", {
            weekday: "short",
            year: "numeric",
            month: "2-digit",
            day: "2-digit",
            minute: "2-digit",
            second: "2-digit",
            hour: "2-digit",
          })}
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
};

export default withRouter(memo(Row));
