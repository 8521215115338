import { useLocation } from "react-router-dom";

import { useCallback, useContext, useEffect, useState } from "react";

import queryString from "query-string";

import { IncomingMessage, OutgoingMessage, SendMessage } from "./Components";
// import { useChatStore, useAuthStore } from "../../../hooks";
import { Box, Divider, Typography } from "@mui/material";
import { BoxChat, TypographyChatActive } from "./style";
import clienteAxios from "../../config/axios";
import { animateScroll } from "react-scroll";
import AuthContext from "../../Context/Auth/AuthContext";
import { v4 } from "uuid";
import AlertaContext from "../../Context/Alerta/AlertaContext";

export function scrollToBottom(id) {
  animateScroll.scrollToBottom({
    containerId: id,
    duration: 0,
  });
}

export function scrollToBottomAnimated(id) {
  animateScroll.scrollToBottom({
    containerId: id,
    duration: 100,
  });
}

export const ChatPage = () => {
  // const { onSelectChat, oneGetMensajes } = useChatStore();
  const location = useLocation();
  const [mensajes, setMensajes] = useState([]);
  const { usuario } = useContext(AuthContext);
  const { mostrarAlerta } = useContext(AlertaContext);
  const [cargando, setCargando] = useState(false);
  const [primeraCarga, setprimeraCarga] = useState(true);
  const [primeraCargaConsulta, setprimeraCargaConsulta] = useState(true);
  const [pagina, setPagina] = useState(0);
  const { id = "", titulo = "" } = queryString.parse(location.search);
  const getChat = useCallback(
    async (id, page) => {
      setCargando(true);
      const res = await clienteAxios.get(
        `/asistente_virtual/conversaciones/${id}`
      );
      console.log([...res.data.reverse()]);

      setMensajes((prev) => [...res.data.reverse(), ...prev]);
      const mensajesElement = document.getElementById("mensajes");
      if (mensajesElement && mensajesElement.scrollTop === 0) {
        mensajesElement.scrollTop = 10; // Ajusta este valor según sea necesario
      }
      setCargando(false);
      // const ultimoMensaje =
      //   res.data.data.results[res.data.data.results.length - 1];
    },
    [primeraCargaConsulta]
  );
  useEffect(() => {
    if (id !== "") {
      getChat(Number(id), pagina);
    }
  }, [id, pagina]);
  const handleScroll = (e) => {
    const element = e.target;
    if (element.scrollTop === 0 && !cargando) {
      // setPagina((prev) => prev + 1);
    }
  };

  useEffect(() => {
    console.log(mensajes.length);
    if (mensajes.length !== 0 && primeraCarga) {
      setTimeout(() => {
        setprimeraCarga(false);
        scrollToBottomAnimated("mensajes");
      }, 250);
    }
  }, [mensajes]);
  const handleEliminar = useCallback(async (message_id) => {}, []);

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
        width: "100%",
        height: "80vh",
        ".swal2-container": {
          zIndex: 1301,
        },
        ".swal2-popup": {
          background: "red",
        },
      }}
    >
      <Box>
        <Divider
          sx={{
            display: "flex",

            alignItems: "center",
          }}
        >
          <TypographyChatActive
            variant="subtitle2"
            color="primary"
            textAlign={"center"}
            fontWeight={"700"}
          >
            {titulo}
          </TypographyChatActive>
          {cargando && <Typography variant="overline">CARGANDO...</Typography>}
        </Divider>
      </Box>
      <BoxChat id="mensajes" onScroll={handleScroll}>
        {mensajes.map((msg) =>
          msg.tipo_mensaje !== "respuesta" ? (
            <OutgoingMessage
              msg={msg}
              key={msg.message_id}
              handleEliminar={handleEliminar}
            />
          ) : (
            <IncomingMessage msg={msg} key={msg.message_id} />
          )
        )}

        {/* <!-- Historia Fin --> */}
      </BoxChat>
      <SendMessage
        titulo={titulo}
        id={id} 
        setMensajes={setMensajes}
      />
    </Box>
  );
};

export default ChatPage;
