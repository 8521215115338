import React, { useState, useContext, useEffect, memo } from "react";
// MODULOS
import Draggable from "react-draggable";

// FUNCIONES
import { replaceCaracteres, trimPropsItem } from "../../../config/const";

// AXIOS
import clienteAxios from "../../../config/axios";
// CONTEXT
import AlertaContext from "../../../Context/Alerta/AlertaContext";
import AuthContext from "../../../Context/Auth/AuthContext";
// MATERIAL
import {
  Modal,
  Box,
  Button,
  Divider,
  Typography,
  IconButton,
  TextField,
  FormControl,
  Autocomplete,
  Tooltip,
  MenuItem,
  Paper,
  TableContainer,
  Table,
  TableHead,
  TableBody,
  useTheme,
} from "@mui/material";
import { AddCircle, Check, Close, Save } from "@mui/icons-material";
import { useModalStyle } from "../../../styles/styleModal";
import {
  columnsProductos,
  objectDefault,
  objectDefaultProducto,
  validacion,
} from "../data";
import { useSimpleTable } from "../../../hooks/useSimpleTable";
import ConfirmacionDialog from "../../components/Dialogs/ConfirmacionDialog";
import ToolBarTable from "../../components/Tablas/ToolBarTable";
import TablaCabecera from "../../components//Tablas/TablaCabecera";
import RowProducto from "./Productos/RowProducto";
import { v4 } from "uuid";
import { useDraggable } from "../../../hooks/useDraggable";

function getModalStyle() {
  return {
    borderRadius: "15px",
    position: "absolute",
    overflow: "scroll",
    maxHeight: "95vh",
  };
}

const ModalAnticipos = (props) => {
  //props
  const {
    editar,
    seteditar,
    setopen,
    open,
    ObjectoActivo,
    changeObjetoActivoState,
    socket,
  } = props;
  const [item, setitem] = useState(ObjectoActivo);
  const {
    // prodId,
    // prodPerIdCliente,
    prodPerDocumentoCliente,
    prodPerNombreCliente,
    prodColaboradores,
    prodFormaPago,
    // prodCredito,
    prodAbono,
    // prodValor,
    // prodSaldo,
    prodDetalle,
    // prodEstado,
    prodProceso,
    // prodUser,
    // prodEmpresa,
    // prodFecReg,
    // prodFecUpd,
    prodFechaEntrega,
    prodProductos,
  } = item;
  const { mostrarAlerta } = useContext(AlertaContext);
  const { usuario } = useContext(AuthContext);
  //  STATE
  const [clientesData, setclientesData] = useState([]);
  const [colaboradoresData, setcolaboradoresData] = useState(null);
  const [openDialogClose, setopenDialogClose] = useState(false);
  const [modificado, setmodificado] = useState(false);
  const [equipoData, setequipoData] = useState(null);
  // HOOKS
  const {
    // agregandoProducto,
    // setagregandoProducto,
    pageProducto,
    // setPageProducto,
    rowsPerPageProducto,
    // setRowsPerPageProducto,
    handleChangePageProducto,
    handleChangeRowsPerPageProducto,
  } = useSimpleTable("Producto");

  const { handleDragStart, handleDragEnter, handleDragEnd } = useDraggable();
  // SECCION
  const seccionDefault = {
    seccionDatosPrincipales: true,
    seccionProductos: false,
  };
  const [seccionActive, setseccionActive] = useState(seccionDefault);
  const { seccionDatosPrincipales, seccionProductos } = seccionActive;

  // DE SIEMPRE
  const [cargando, setcargando] = useState(false);
  const [error, seterror] = useState({});
  const clases = useModalStyle();
  const theme = useTheme();
  const [modalStyle] = useState(getModalStyle);
  function getStylesMenuItems(item, array, property) {
    const exist = array.some(
      (arrayItem) => Number(arrayItem[property]) === Number(item[property])
    );
    return {
      fontWeight: exist
        ? theme.typography.fontWeightRegular
        : theme.typography.fontWeightMedium,
      backgroundColor: exist ? theme.palette.primary.main : "white",
    };
  }
  useEffect(() => {
    if (open) {
      setmodificado(true);
    }
    // eslint-disable-next-line
  }, [item]);
  useEffect(() => {
    const getequipoData = async () => {
      try {
        const resequipoData = await clienteAxios("/maquinarias/filtro/0");
        setequipoData(resequipoData.data.data);
      } catch (error) {
        setequipoData([]);
      }
    };
    if (!equipoData) {
      getequipoData();
    }
    const getcolaboradoresData = async () => {
      try {
        const rescolaboradoresData = await clienteAxios(
          `/proveedores/filtro/0?&search=${""}&estado=${"USUARIO"}&edicion=${false}`
        );
        setcolaboradoresData(rescolaboradoresData.data.data);
      } catch (error) {
        setcolaboradoresData([]);
      }
    };
    if (!colaboradoresData) {
      getcolaboradoresData();
    }

    setitem(ObjectoActivo);

    // eslint-disable-next-line
  }, [ObjectoActivo]);

  const defaultActive = (args) => {
    changeObjetoActivoState(objectDefault);
    setitem(objectDefault);

    setopen(false);
    seteditar(false);
    seterror({});
    setcargando(false);
    setseccionActive(seccionDefault);
    setopenDialogClose(false);
    setmodificado(false);
  };
  const handleChange = (e) => {
    const res = replaceCaracteres(e.target.value);
    setitem({
      ...item,
      [e.target.name]: res,
    });
  };

  const handleCrear = async () => {
    try {
      if (validacion(item, setitem, seterror)) {
        return mostrarAlerta("Faltan campos obligatorios", "error");
      }
      setcargando(true);

      socket.current.emit(
        "client:guardarData",
        trimPropsItem({
          ...item,
          tabla: "produccion",
          rucempresa: usuario.rucempresa,
          rol: usuario.rol,
          prodUser: usuario.usuario,
          prodEmpresa: usuario.rucempresa,
        })
      );
      setcargando(false);
      return defaultActive({ eliminarForm: true });
    } catch (error) {
      setcargando(false);
      mostrarAlerta("Hubo un error", "error");
      return defaultActive();
    }
  };
  const handleEditar = async () => {
    try {
      if (validacion(item, setitem, seterror)) {
        return mostrarAlerta("Faltan campos obligatorios", "error");
      }
      setcargando(true);

      socket.current.emit(
        "client:actualizarData",
        trimPropsItem({
          ...item,
          tabla: "produccion",
          rucempresa: usuario.rucempresa,
          rol: usuario.rol,
          prodUser: usuario.usuario,
          prodEmpresa: usuario.rucempresa,
        })
      );
      setcargando(false);
      return defaultActive({ eliminarForm: true });
    } catch (error) {
      setcargando(false);
      mostrarAlerta("Hubo un error", "error");
      return defaultActive();
    }
  };
  const handleGurdar = () => {
    if (editar) {
      handleEditar();
    } else {
      handleCrear();
    }
  };
  if (!equipoData) {
    return null;
  }
  if (!colaboradoresData) {
    return null;
  }
  return (
    <Modal
      open={open}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box>
        <ConfirmacionDialog
          open={openDialogClose}
          setopen={() => setopenDialogClose(false)}
          titulo={"Desea mantener los cambios?"}
          categoria="warning"
          botones={[
            {
              tooltip: "Mantener datos",
              texto: "SI",
              funcion: () => {
                defaultActive({ eliminarForm: false });
              },
              disabled: false,
              Icon: Check,
              color: "secondary",
              id: 1,
              ocultar: false,
              tipo: "boton",
              variante: "contained",
              size: "medium",
              sx: { marginRight: ".3rem" },
            },
            {
              tooltip: "Vaciar datos",
              texto: "NO",
              funcion: () => {
                defaultActive({ eliminarForm: true });
              },
              disabled: false,
              Icon: Close,
              color: "error",
              id: 2,
              ocultar: false,
              tipo: "boton",
              variante: "contained",
              size: "medium",
              sx: {},
            },
          ]}
        />
        <Draggable handle="#handle">
          <Box display="flex" justifyContent="center">
            <div style={modalStyle} className={clases.paper}>
              <Box
                display="flex"
                alignItems="center"
                justifyContent="space-between"
                width="100%"
              >
                <Typography
                  style={{ cursor: "-webkit-grab" }}
                  id="handle"
                  variant="button"
                  color="initial"
                  align="center"
                >
                  {`${editar ? "Editar" : "Agregar"} produccion.`}
                  <Tooltip title={"Guardar"} followCursor placement="top-start">
                    <IconButton
                      onClick={() => {
                        handleGurdar();
                      }}
                    >
                      <Save color="secondary" />
                    </IconButton>
                  </Tooltip>
                </Typography>
                <Box display="flex" alignItems="center">
                  <IconButton
                    onClick={() => {
                      return defaultActive({ eliminarForm: true });
                    }}
                  >
                    <Close color={modificado ? "error" : "primary"} />
                  </IconButton>
                </Box>
              </Box>
              <Divider />
              <Box display="flex" justifyContent="space-between" width="100%">
                <Box display={"flex"} flexWrap={"wrap"}>
                  <Button
                    variant={
                      seccionDatosPrincipales
                        ? "contained"
                        : error.prodPerDocumentoCliente ||
                          error.prodColaboradores ||
                          error.prodFormaPago ||
                          error.prodAbono ||
                          error.prodDetalle ||
                          error.prodEstado ||
                          error.prodProceso ||
                          error.prodFechaEntrega
                        ? "outlined"
                        : "null"
                    }
                    color={
                      error.prodPerDocumentoCliente ||
                      error.prodColaboradores ||
                      error.prodFormaPago ||
                      error.prodAbono ||
                      error.prodDetalle ||
                      error.prodEstado ||
                      error.prodProceso ||
                      error.prodFechaEntrega
                        ? "error"
                        : "primary"
                    }
                    disableElevation
                    className={clases.buttonTab}
                    size="small"
                    onClick={() =>
                      setseccionActive({ seccionDatosPrincipales: true })
                    }
                  >
                    Datos Principales
                  </Button>
                  <Button
                    variant={
                      seccionProductos
                        ? "contained"
                        : error.prodProductos
                        ? "outlined"
                        : "null"
                    }
                    color={error.prodProductos ? "error" : "primary"}
                    disableElevation
                    className={clases.buttonTab}
                    size="small"
                    onClick={() => setseccionActive({ seccionProductos: true })}
                  >
                    Productos
                  </Button>
                </Box>
                {/* <FormControlLabel
                  control={
                    <Switch
                      checked={resEstado === "ACTIVA" ? true : false}
                      onChange={(e) => {
                        setitem({
                          ...item,
                          resEstado: e.target.checked ? "ACTIVA" : "INACTIVA",
                        });
                      }}
                      color="primary"
                    />
                  }
                  label="Estado"
                /> */}
              </Box>
              <Divider />
              <Box className={clases.container}>
                {seccionDatosPrincipales && (
                  <>
                    <FormControl className={clases.formD}>
                      <Autocomplete
                        disabled={editar}
                        size="small"
                        options={clientesData.map((item) => {
                          return (
                            item.perDocNumero +
                            "/+/" +
                            item.perNombre +
                            "/+/" +
                            item.perId
                          );
                        })}
                        getOptionLabel={(option) => {
                          return option.replace("/+/", " ").split("/+/")[0];
                        }}
                        value={`${prodPerDocumentoCliente} ${prodPerNombreCliente}`.trim()}
                        onChange={async (event, newValue) => {
                          if (newValue) {
                            const options = newValue.split("/+/");

                            const itemF = clientesData.find((itemF) => {
                              return itemF.perId === Number(options[2]);
                            });

                            setitem({
                              ...item,
                              prodPerDocumentoCliente: itemF.perDocNumero,
                              prodPerNombreCliente: itemF.perNombre,
                              prodPerIdCliente: itemF.perId,
                            });
                          }
                        }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            size="small"
                            label="Cliente"
                            error={error.prodPerDocumentoCliente}
                            onChange={async (e) => {
                              if (e.target.value === "") {
                                return;
                              }
                              try {
                                const res = await clienteAxios.get(
                                  `/proveedores/filtro/0?empresa=${
                                    usuario.rucempresa
                                  }&search=${
                                    e.target.value
                                  }&estado=${"CLIENTE"}&tabla=ventas`
                                );

                                setclientesData(res.data.data);
                              } catch (error) {
                                return mostrarAlerta("Hubo un error", "error");
                              }
                            }}
                            InputProps={{
                              ...params.InputProps,
                            }}
                          />
                        )}
                      />
                    </FormControl>

                    <TextField
                      inputProps={{ className: clases.inputPadding }}
                      className={clases.formD}
                      size="small"
                      label="Colaboradores"
                      name="prodColaboradores"
                      disabled={editar}
                      value={prodColaboradores.map(
                        (item) =>
                          item.perDocNumero +
                          "/+/" +
                          item.perNombre +
                          "/+/" +
                          item.perId
                      )}
                      onChange={(e) => {
                        const prodColaboradores = e.target.value.map(
                          (value) => {
                            const [
                              perDocNumero,
                              perNombre,
                              perId,
                            ] = value.split("/+/");
                            return {
                              perDocNumero,
                              perNombre,
                              perId,
                            };
                          }
                        );
                        setitem({
                          ...item,
                          prodColaboradores,
                        });
                      }}
                      select
                      error={error.prodColaboradores}
                      MenuProps={{
                        PaperProps: {
                          style: {
                            maxHeight: 48 * 4.5 + 8,
                            width: 250,
                          },
                        },
                      }}
                      SelectProps={{
                        displayEmpty: true,
                        multiple: true,
                      }}
                    >
                      {colaboradoresData.map((item) => {
                        return (
                          <MenuItem
                            style={getStylesMenuItems(
                              item,
                              prodColaboradores,
                              "perId"
                            )}
                            value={
                              item.perDocNumero +
                              "/+/" +
                              item.perNombre +
                              "/+/" +
                              item.perId
                            }
                          >
                            {item.perDocNumero + " - " + item.perNombre}
                          </MenuItem>
                        );
                      })}
                    </TextField>
                    <TextField
                      disabled={editar}
                      label="Forma de Pago"
                      error={error.prodFormaPago}
                      value={prodFormaPago}
                      name="prodFormaPago"
                      onChange={(e) => handleChange(e)}
                      className={clases.formD}
                      size="small"
                      select
                      InputLabelProps={{ shrink: true }}
                      SelectProps={{ displayEmpty: true }}
                    >
                      <MenuItem disabled value="">
                        Seleccione.
                      </MenuItem>
                      {[
                        "EFECTIVO",
                        "CHEQUE",
                        "TRANSFERENCIA",
                        "DEPOSITO",
                        "CRUCE",
                      ].map((item) => {
                        return (
                          <MenuItem key={item} value={item}>
                            {item}
                          </MenuItem>
                        );
                      })}
                    </TextField>
                    <TextField
                      disabled={editar}
                      type="number"
                      label="Abono"
                      error={error.prodAbono}
                      value={prodAbono}
                      name="prodAbono"
                      onChange={(e) => handleChange(e)}
                      className={clases.formD}
                      size="small"
                    />

                    <TextField
                      label="Estado"
                      error={error.prodProceso}
                      value={prodProceso}
                      name="prodProceso"
                      onChange={(e) => handleChange(e)}
                      className={clases.formD}
                      size="small"
                      select
                      InputLabelProps={{ shrink: true }}
                      SelectProps={{ displayEmpty: true }}
                    >
                      <MenuItem disabled value="">
                        Seleccione.
                      </MenuItem>
                      {["ANULADO", "ENTREGADO", "EN PROCESO"].map((item) => {
                        return (
                          <MenuItem key={item} value={item}>
                            {item}
                          </MenuItem>
                        );
                      })}
                    </TextField>

                    <TextField
                      type="date"
                      InputLabelProps={{ shrink: true }}
                      label="Fecha Entrega"
                      error={error.prodFechaEntrega}
                      value={prodFechaEntrega}
                      name="prodFechaEntrega"
                      onChange={(e) => handleChange(e)}
                      className={clases.formAllW}
                      size="small"
                    />
                    <TextField
                      multiline
                      rows={3}
                      variant="outlined"
                      label="Detalle"
                      error={error.prodDetalle}
                      value={prodDetalle}
                      name="prodDetalle"
                      onChange={(e) => handleChange(e)}
                      className={clases.formD}
                      size="small"
                    />
                  </>
                )}
                {seccionProductos && (
                  <>
                    <Paper sx={{ width: "100%" }}>
                      <ToolBarTable
                        count={prodProductos.length}
                        rowsPerPage={rowsPerPageProducto}
                        page={pageProducto}
                        handleChangePage={handleChangePageProducto}
                        handleChangeRowsPerPage={
                          handleChangeRowsPerPageProducto
                        }
                        ocultarPaginacion={true}
                        simplePagination={true}
                        botonesIzquierdos={[
                          {
                            tooltip: "Agregar",
                            texto: "Agregar",
                            funcion: () => {
                              setitem((prev) => {
                                const prodProductos = [
                                  {
                                    ...objectDefaultProducto,
                                    id: v4(),
                                    nuevo: true,
                                    incluyeIva:
                                      usuario.rucempresa === "DAYA"
                                        ? "SI"
                                        : objectDefaultProducto.incluyeIva,
                                    llevaIva:
                                      usuario.rucempresa === "DAYA"
                                        ? "SI"
                                        : objectDefaultProducto.llevaIva,
                                  },
                                  ...prev.prodProductos.map((itemProducto) => ({
                                    ...itemProducto,
                                    order: itemProducto.order + 1,
                                  })),
                                ];
                                return {
                                  ...prev,
                                  prodProductos,
                                };
                              });
                            },
                            disabled: false,
                            Icon: AddCircle,
                            color: "primary",
                            id: 1,
                            ocultar: editar,
                            tipo: "boton",
                            variante: "contained",
                            size: "medium",
                            sx: {},
                          },
                        ]}
                        botonesDerechos={[]}
                      />

                      <TableContainer>
                        <Table
                          stickyHeader
                          aria-label="sticky table"
                          size="small"
                        >
                          <TableHead>
                            <TablaCabecera
                              columns={columnsProductos}
                              habilitarOrdenar={false}
                            />
                          </TableHead>

                          <TableBody component={"div"}>
                            {prodProductos
                              .sort((a, b) => a.order - b.order)
                              .map((rowHijo, index) => {
                                return (
                                  <RowProducto
                                    editarPadre={editar}
                                    equipoData={equipoData}
                                    setitemPadre={setitem}
                                    itemPadre={item}
                                    rowHijo={rowHijo}
                                    index={index}
                                    key={rowHijo.id}
                                    // Draggable
                                    handleDragStart={handleDragStart}
                                    handleDragEnter={handleDragEnter}
                                    handleDragEnd={handleDragEnd}
                                  />
                                );
                              })}
                          </TableBody>
                        </Table>
                      </TableContainer>
                    </Paper>
                  </>
                )}
              </Box>
              <Divider />
              <Box
                display="flex"
                width="100%"
                justifyContent="space-between"
                alignItems="center"
              >
                <Box
                  width="100%"
                  display="flex"
                  justifyContent="flex-end"
                  mt={1}
                >
                  <Button
                    style={{ width: "30%" }}
                    variant="contained"
                    color="primary"
                    disabled={cargando}
                    onClick={() => {
                      handleGurdar();
                    }}
                  >
                    Guardar
                  </Button>
                </Box>
              </Box>
            </div>
          </Box>
        </Draggable>
      </Box>
    </Modal>
  );
};

export default memo(ModalAnticipos);
