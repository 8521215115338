import React, { memo, useState, useContext, useEffect, useRef } from "react";

import { Box, Button, Divider } from "@mui/material";

import Tabla from "./Componentes/Tabla";
import ModalB from "../../../components/Extra/ModalB";
import BarraTablas from "../../../components/BarraTablas/BarraTablas";
import clienteAxios from "../../../config/axios";
import AlertaContext from "../../../Context/Alerta/AlertaContext";
import AuthContext from "../../../Context/Auth/AuthContext";
import Modal from "./Componentes/Modal";
import io from "socket.io-client";
import { FechaExactaConMinutos, socketUrl } from "../../../config/const";
import { usePermiso } from "../../../hooks/usePermiso";
import ModalPropietario from "../Personas_Veterinaria/Componentes/Modal";
import { getMessages, localizer } from "./helpers";

import { Calendar } from "react-big-calendar";
import "react-big-calendar/lib/css/react-big-calendar.css";
import { CalendarEvent } from "./Componentes/CalendarEvent";
import { useModalStyle } from "../../../styles/styleModal";
import { useTheme } from "@emotion/react";
import {
  startOfMonth,
  endOfMonth,
  startOfWeek,
  endOfWeek,
  addDays,
  addHours,
} from "date-fns";
import ConfirmacionDialog from "../../../components/Extra/ConfirmacionDialog";
const Reservas = (props) => {
  const tema = useTheme();
  const { tienePermiso, alertaPermiso } = usePermiso("Reservas");

  const {
    idmascota,
    settab,
    setarrayExportHijos,
    desdeOtroLugarMascota,
    puedeExportar,
    itemRelacionadoPadre,
    desdeDashboard,
  } = props;
  // DATA DEL STATE
  useEffect(() => {
    if (desdeOtroLugarMascota) {
      settab("");
      setarrayExportHijos(arrayExport.map((item) => item[campoExport]));
    }
    // eslint-disable-next-line
  }, [arrayExport]);
  const [openPropietarioModal, setopenPropietarioModal] = useState(false);
  const [rows, setrows] = useState(null);
  const [eliminado, seteliminado] = useState(false);
  const columns = [
    // {
    //   id: "hcliMascNombre",
    //   label: "Mascota",
    //   minWidth: 25,
    //   tipo: "string",
    //   orden: 3,
    //   propiedad: "hcliMascNombre",
    //   variante: "autocompleteDinamico",
    //   propiedadesMostrar: 2,
    //   campoData1: "mascNombre",
    //   campoData2: "mascPerNombre",
    //   getData: async (data) => {
    //     const res = await clienteAxios.get(`/mascotas/filtro/0?search=${data}`);
    //     return res;
    //   },
    // },
    // {
    //   id: "hcliPerNombreVetInt",
    //   label: "Vet. Interno",
    //   minWidth: 25,
    //   tipo: "string",
    //   orden: 3,
    //   propiedad: "hcliPerNombreVetInt",
    //   variante: "autocompleteDinamico",
    //   propiedadesMostrar: 2,
    //   campoData1: "perDocNumero",
    //   campoData2: "perNombre",
    //   getData: async (data) => {
    //     const res = await clienteAxios.get(
    //       `/proveedores/filtro/0?search=${data}&estado=VETERINARIO`
    //     );
    //     return res;
    //   },
    // },

    {
      id: "resId",
      label: "Código",
      minWidth: 25,
      tipo: "string",
      orden: 1,
      propiedad: "resId",
    },
    {
      id: "resPerNombre",
      label: "Cliente",
      minWidth: 25,
      tipo: "string",
      propiedad: "resPerNombre",
      variante: "autocompleteDinamico",
      propiedadesMostrar: 2,
      campoData1: "perDocNumero",
      campoData2: "perNombre",
      getData: async (data) => {
        const res = await clienteAxios.get(
          `/proveedores/filtro/0?search=${data}&estado=CLIENTE`
        );
        return res;
      },
    },
    {
      id: "resMascNombre",
      label: "Mascota",
      minWidth: 25,
      tipo: "string",
      orden: 1,
      propiedad: "resMascNombre",
      variante: "autocompleteDinamico",
      propiedadesMostrar: 2,
      campoData1: "mascNombre",
      campoData2: "mascPerNombre",
      getData: async (data) => {
        const res = await clienteAxios.get(`/mascotas/filtro/0?search=${data}`);
        return res;
      },
    },

    {
      id: "resMascRaza",
      label: "Raza",
      minWidth: 25,
      tipo: "string",
      orden: 1,
      propiedad: "resMascRaza",
    },

    {
      id: "resTipoReserva",
      label: "Reserva",
      minWidth: 25,
      tipo: "string",
      orden: 1,
      propiedad: "resTipoReserva",
      variante: "autocompleteDataDefault",
      propiedadesMostrar: 1,
      campoData1: "tiporesNombre",
      getData: async () => {
        const res = await clienteAxios.get("/tiporeservas/filtro/0");
        return res;
      },
    },

    {
      id: "resFecha",
      label: "Fecha",
      minWidth: 25,
      tipo: "fecha",
      orden: 1,
      propiedad: "resFecha",
    },
    {
      id: "resPerNomVeterinario",
      label: "Veterinario",
      minWidth: 25,
      tipo: "string",
      orden: 1,
      propiedad: "resPerNomVeterinario",
      variante: "autocompleteDinamico",
      propiedadesMostrar: 2,
      campoData1: "perDocNumero",
      campoData2: "perNombre",
      getData: async (data) => {
        const res = await clienteAxios.get(
          `/proveedores/filtro/0?search=${data}&estado=VETERINARIO`
        );
        return res;
      },
    },

    {
      id: "resObservacion",
      label: "Observación",
      minWidth: 25,
      tipo: "string",
      orden: 1,
      propiedad: "resObservacion",
    },
    {
      id: "resFecUpd",
      label: "Fec Upd",
      minWidth: 25,
      tipo: "fecha",
      orden: 1,
      propiedad: "resFecUpd",
    },
    {
      id: "resUser",
      label: "Usuario",
      minWidth: 25,
      tipo: "string",
      orden: 1,
      propiedad: "resUser",
    },
    {
      id: "resEnvioCorreo",
      label: "Notif. Email",
      minWidth: 25,
      tipo: "string",
      orden: 1,
      propiedad: "resEnvioCorreo",
      variante: "autocompleteComun",
      data: ["PENDIENTE", "ENVIADO", "ERROR"],
    },
    {
      id: "resEnvioSms",
      label: "Notif. Sms",
      minWidth: 25,
      tipo: "string",
      orden: 1,
      propiedad: "resEnvioSms",
      variante: "autocompleteComun",
      data: ["PENDIENTE", "ENVIADO", "ERROR"],
    },
  ];
  const defaultFullBuscador = () => {
    const json = {};
    columns.forEach((itemPropiedad) => {
      Object.entries({ itemPropiedad }).forEach((item) => {
        if (itemPropiedad.tipo === "string") {
          json[`${item[1].propiedad}`] = "";
        } else if (itemPropiedad.tipo === "numero") {
          json[`${item[1].propiedad}1`] = "";
          json[`${item[1].propiedad}2`] = "";
        } else if (itemPropiedad.tipo === "fecha") {
          json[`${item[1].propiedad}1`] = "";
          json[`${item[1].propiedad}2`] = "";
        }
      });
    });

    return json;
  };
  const [ObjectoActivo, setObjetoActivo] = useState({
    // resId: 1,
    resMascId: "",
    resMascNombre: "",
    resMascRaza: "",
    resPerId: "",
    resPerDocumento: "",
    resPerNombre: "",
    resPerCelular: [],
    resPerEmail: [],
    resTipoReserva: "",
    resUltima: "NO",
    resPerDocVeterinario: "",
    resPerNomVeterinario: "",
    resPerIdVeterinario: "",
    resFecha: FechaExactaConMinutos,
    resEmpresa: "",
    resUser: "",
    resObservacion: "",
    resEnvioCorreo: "PENDIENTE",
    resEnvioSms: "PENDIENTE",
    resBgColor: tema.palette.primary.main,
    resFechaFinal: new Date(
      Date.parse(addHours(Date.parse(FechaExactaConMinutos), 1)) -
        new Date().getTimezoneOffset() * 60 * 1000
    )
      .toISOString()
      .substring(0, 16),
  });
  const [pagination, setpagination] = useState(null);
  // FUNCIONES STATE
  const getDataState = async (
    cantidad,
    page,
    search,
    input,
    orden,
    datos,
    rucempresa,
    eliminado
  ) => {
    try {
      console.log(JSON.parse(datos));
      
      const res = await clienteAxios.get(
        `/reservas/?cantidad=${cantidad}&page=${page}&search=${
          search ? search : ""
        }&input=${input ? input : ""}&orden=${orden ? orden : ""}&datos=${
          datos ? datos : ""
        }&rucempresa=${rucempresa ? rucempresa : ""}&idmascota=${
          idmascota ? idmascota : ""
        }&allData=${tienePermiso("allData")}&eliminado=${eliminado}`
      );

      return res;
    } catch (error) {}
  };

  const obtenerState = async (
    cantidad = 10,
    page = 0,
    search,
    input,
    orden,
    datos,
    rucempresa,
    eliminado
  ) => {
    const res = await getDataState(
      cantidad,
      page,
      search,
      input,
      orden,
      datos,
      rucempresa,
      eliminado
    );
    setrows(res.data.data.results);
    setpagination(res.data.data.pagination);
  };
  const addRowsState = async (items) => {
    setrows([...rows, ...items]);
  };
  const remplazarRowsState = async (
    cantidad,
    page,
    search,
    input,
    orden,
    datos,
    rucempresa,
    eliminado
  ) => {
    const res = await getDataState(
      cantidad,
      page,
      search,
      input,
      orden,
      datos,
      rucempresa,
      eliminado
    );

    setrows(res.data.data.results);
    setpagination(res.data.data.pagination);
    // dispatch({
    //   type: OBTENER,
    //   payload: {
    //     results: res.data.data.results,
    //     pagination: res.data.data.pagination,
    //   },
    // });
  };
  const agregarState = async (item) => {
    try {
      setrows([item, ...rows]);

      setpagination({
        ...pagination,
        numRows: pagination.numRows + 1,
      });
    } catch (error) {}
  };

  const editarState = async (item) => {
    try {
      const resRows = rows.map((itemR) => {
        if (itemR.resId === item.resId) {
          return item;
        } else {
          return itemR;
        }
      });

      setrows(resRows);
    } catch (error) {}
  };

  const eliminarState = async (_id) => {
    try {
      const resRows = rows.filter((row) => {
        return row.resId !== _id;
      });
      setrows(resRows);
      setpagination({
        ...pagination,
        numRows: pagination.numRows - 1,
      });
    } catch (error) {}
  };
  const eliminarSeleccionState = async (_ids) => {
    const resRows = rows.filter((row) => {
      return !_ids.some((item) => {
        return item === row.resId;
      });
    });
    setpagination({
      ...pagination,
      numRows: pagination.numRows - _ids.length,
    });
    setrows(resRows);
  };

  const changeObjetoActivoState = (item) => {
    setObjetoActivo(item);
    // dispatch({
    //   type: CHANGE_ACTIVO,
    //   payload: item,
    // });
  };

  const rowsImportState = (rows) => {
    // dispatch({
    //   type: IMPORTAR_ROWS,
    //   payload: rows,
    // });
    setrows(rows);
  };
  const [ObjectoActivoPropietario, setObjetoActivoPropietario] = useState({
    perISPContratos: [],
    perNombreComercial: "",
    perEmpresa: [],
    perPais: "",
    perProvincia: "",
    perCanton: "",
    perTipoProveedor: "BIENES",
    perTiempoCredito: "0",
    perDocTipo: "",
    perDocNumero: "",
    perPerfil: [],
    perNombre: "",
    perDireccion: "",
    perObligado: "NO",
    perCredito: "NO",
    perTelefono: "0",
    perCelular: ["+593"],
    perEstado: "ACTIVO",
    perObsevacion: "",
    perEmail: ["sin@sincorreo.com"],
    perOtros: ["ZZZ9999"],
    perNickname: "",
    perUser: "",
    perFecReg: "",
    perFoto: "",
    perUbicacion: {
      longitud: "",
      latitud: "",
    },
    perDocumento: "",
    perGenero: "M",
    perRecomendacion: "",
    perFecNacimiento: "",
    perEspecialidad: "",
    perTitulo: "",
    perSenescyt: "",
    perPersonal: "INTERNO",
  });
  const changeObjetoActivoStatePropietario = (item) => {
    setObjetoActivoPropietario(item);
    // dispatch({
    //   type: CHANGE_ACTIVO,
    //   payload: item,
    // });
  };
  // FIN FUNCIONES STATE
  // FIN DATA STATE

  // FUNCIONES SOCKET
  const [tabla] = useState("reserva");
  const [itemSocket, setitemSocket] = useState({
    tipo: "",
    item: {},
  });

  const socket = useRef();
  useEffect(() => {
    // Creates a WebSocket connection
    socket.current = io(socketUrl);
    socket.current.on("server:guardadoExitoso", (data) => {
      if (data.tabla !== tabla) {
        return;
      }

      setitemSocket({
        tipo: "agregar",
        item: data,
      });
    });
    socket.current.on("server:actualizadoExitoso", (data) => {
      if (data.tabla !== tabla) {
        return;
      }

      setitemSocket({
        tipo: "editar",
        item: data,
      });
    });
    socket.current.on("server:eliminadoExitoso", (data) => {
      if (data.tabla !== tabla) {
        return;
      }
      setitemSocket({
        tipo: "eliminar",
        item: data,
      });
    });
    socket.current.on("server:eliminadoExitosoSeleccionado", (data) => {
      if (data.tabla !== tabla) {
        return;
      }
      setitemSocket({
        tipo: "eliminarSeleccion",
        item: data,
      });
    });

    socket.current.on("server:error", (data) => {
      mostrarAlerta(
        data.msg ? data.msg : "Hubo un error",
        data.alert ? data.alert : "error"
      );
    });
    return () => {
      socket.current.disconnect();
    };
    // Destroys the socket reference
    // when the connection is closed
    // eslint-disable-next-line
  }, []);
  useEffect(() => {
    const { item, tipo } = itemSocket;

    if (tipo === "") {
      return;
    }

    if (!rows && dataCalendar.length === 0) {
      return;
    }
    if (usuario.rucempresa !== item.rucempresa) {
      return;
    }
    if (item.tabla !== tabla) {
      return;
    }

    if (desdeOtroLugarMascota && Number(item.hcliMascId) !== idmascota) {
      return;
    }

    const funcionAalerta = () => {
      if (item.msg === "") {
        return null;
      }
      if (!item.msg) {
        return null;
      }
      mostrarAlerta(item.msg, item.alert ? item.alert : "success");
    };

    if (lastView === "agenda") {
      if (tipo === "agregar") {
        agregarState(item);
      }
      if (tipo === "editar") {
        editarState(item);
      }
      if (tipo === "eliminar") {
        eliminarState(item.resId);
      }
      if (tipo === "eliminarSeleccion") {
        eliminarSeleccionState(item.lista);
      }
    } else {
      if (tipo === "agregar") {
        setdataCalendar(
          [item, ...dataCalendar].map((reserva) => ({
            ...reserva,
            start: new Date(reserva.resFecha),
            end: new Date(reserva.resFechaFinal),
          }))
        );
      }

      if (tipo === "editar") {
        const res = dataCalendar.map((reserva) => {
          if (reserva.resId === item.resId) {
            return {
              ...item,
              start: new Date(item.resFecha),
              end: new Date(item.resFechaFinal),
            };
          }
          return reserva;
        });

        setdataCalendar(res);
      }
      if (tipo === "eliminar") {
        setdataCalendar(
          dataCalendar.filter((reserva) => reserva.resId !== item.resId)
        );
      }
      if (tipo === "eliminarSeleccion") {
        eliminarSeleccionState(item.lista);
      }
    }

    funcionAalerta();
    setitemSocket({ tipo: "", item: {} });
    // eslint-disable-next-line
  }, [itemSocket]);

  const [open, setopen] = useState(false);
  const [buscando, setbuscando] = useState(false);
  const [editar, seteditar] = useState(false);
  const [search, setsearch] = useState("");
  const [cargando, setcargando] = useState(false);
  const [input, setinput] = useState({
    id: "resFecUpd",
    label: "ID",
    minWidth: 150,
    tipo: "string",

    propiedad: "resFecUpd",
  });
  const [arrayExport, setarrayExport] = useState([]);
  const [campoExport] = useState("resId");

  const { mostrarAlerta } = useContext(AlertaContext);
  const {
    usuario: { rucempresa },
    usuario,
  } = useContext(AuthContext);
  const [orden, setorden] = useState(false);
  const [openMB, setopenMB] = useState(false);
  const [fullBuscador, setfullBuscador] = useState({});

  //pagina actual
  const [page, setPage] = React.useState(0);
  //filas por pagina
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  // input
  //
  const handleChange = (e) => {
    setfullBuscador({ ...fullBuscador, [e.target.name]: e.target.value });
  };
  const funcionB = async () => {
    setcargando(true);
    await remplazarRowsState(
      rowsPerPage,
      0,
      search,
      input.id,
      orden,
      JSON.stringify(fullBuscador),
      rucempresa,
      eliminado
    );
    setPage(0);
    setbuscando(true);
    setopenMB(false);
    setcargando(false);
  };

  const handleSearch = async () => {
    setarrayExport([]);
    setcargando(true);
    await remplazarRowsState(
      rowsPerPage,
      0,
      search,
      input.id,
      orden,
      JSON.stringify(fullBuscador),
      rucempresa,
      eliminado
    );

    setPage(0);
    setcargando(false);
  };
  useEffect(() => {
    const fullBRes = defaultFullBuscador();

    setfullBuscador(fullBRes);
    // eslint-disable-next-line
  }, []);
  const funcionDefault = async () => {
    setcargando(true);
    const fullBRes = defaultFullBuscador();
    setfullBuscador(fullBRes);
    await remplazarRowsState(
      rowsPerPage,
      0,
      "",
      input.id,
      orden,
      JSON.stringify(fullBRes),
      rucempresa,
      eliminado
    );

    setPage(0);
    setsearch("");
    setbuscando(false);
    setcargando(false);
    setopenMB(false);
  };
  const clases = useModalStyle();
  const [dataCalendar, setdataCalendar] = useState([]);
  const [lastView, setlastView] = useState(
    localStorage.getItem("lastView") || "week"
  );
  const eventStyleGetter = (event, start, end, isSelected) => {
    const style = {
      backgroundColor: isSelected ? "black" : event.resBgColor,
      borderRadius: "0px",
      color: "white",
    };
    return { style };
  };

  const onDoubleClick = (event) => {
    seteditar(true);
    changeObjetoActivoState(event);
    setopen(true);
  };
  const onSelect = (event) => {};
  const onViewChange = (event) => {
    setlastView(event);
    localStorage.setItem("lastView", event);
  };
  const onLoadCurrentViewDates = async () => {
    if (lastView === "agenda") return;
    const thisDate = new Date();
    let start = "";
    let end = "";
    if (lastView === "month") {
      start = addDays(startOfMonth(thisDate), -6);
      end = addDays(endOfMonth(thisDate), 6);
    }
    if (lastView === "week") {
      start = addDays(startOfWeek(thisDate), -1);
      end = addDays(endOfWeek(thisDate), 1);
    }
    if (lastView === "day") {
      start = thisDate;
    }
    try {
      setcargando(true);

      const res = await clienteAxios.get(
        `reservas/toCalendar?tipo=${lastView}&fechas=${JSON.stringify({
          start,
          end,
        })}&primeraCarga=${true}`
      );

      setdataCalendar([
        ...res.data.data.map((reserva) => ({
          ...reserva,
          start: new Date(reserva.resFecha),
          end: new Date(reserva.resFechaFinal),
        })),
      ]);
      setcargando(false);
    } catch (error) {
      setdataCalendar([]);
      setcargando(false);
    }
  };
  useEffect(() => {
    onLoadCurrentViewDates();
    // eslint-disable-next-line
  }, []);
  const handleEliminar = async () => {
    if (!tienePermiso("eliminar")) {
      return alertaPermiso("eliminar");
    }
    try {
      socket.current.emit("client:eliminarData", {
        nombre: usuario.nombre,
        tabla: "reserva",
        rucempresa: usuario.rucempresa,
        rol: usuario.rol,
        resUser: usuario.usuario,
        resEmpresa: usuario.rucempresa,
        resId: openConfirmDialog.resId,
      });
      setopenConfirmDialog(null);
    } catch (error) {}
  };
  const [openConfirmDialog, setopenConfirmDialog] = useState(null);
  return (
    <Box>
      <ConfirmacionDialog
        open={!!openConfirmDialog}
        setopen={(e) => {
          setopenConfirmDialog(null);
        }}
        titulo={`¿Esta seguro de eliminar este registro?`}
        funcion={handleEliminar}
      />
      <BarraTablas
        ocultarBuscar={lastView !== "agenda"}
        search={search}
        setsearch={setsearch}
        funcion={handleSearch}
        buscando={buscando}
        setbuscando={setbuscando}
        setopen={(boolean) => {
          if (desdeOtroLugarMascota) {
            setObjetoActivo({
              ...ObjectoActivo,
              hcliMascId: itemRelacionadoPadre.mascId,
              hcliMascNombre: itemRelacionadoPadre.mascNombre,
              hcliPerId: itemRelacionadoPadre.mascPerId,
              hcliPerNombre: itemRelacionadoPadre.mascPerNombre,
            });
          }
          setopen(boolean);
        }}
        open={open}
        openMB={openMB}
        setopenMB={setopenMB}
        LabelBuscador="Buscar por: Nombre Mascota, Nombre Propietario"
        funcionDefault={funcionDefault}
        subirArchivo={false}
        ocultarMigasDePan={desdeOtroLugarMascota || desdeDashboard}
        ocultarOpciones={true}
      />
      <ModalPropietario
        estadoRequest={"CLIENTE"}
        editar={true}
        seteditar={() => {}}
        setopen={setopenPropietarioModal}
        open={openPropietarioModal}
        ObjectoActivo={ObjectoActivoPropietario}
        changeObjetoActivoState={changeObjetoActivoStatePropietario}
        socket={socket}
      />
      <Modal
        editar={editar}
        seteditar={seteditar}
        setopen={setopen}
        open={open}
        ObjectoActivo={ObjectoActivo}
        changeObjetoActivoState={changeObjetoActivoState}
        socket={socket}
      />

      {lastView === "agenda" ? (
        <>
          <Box display="flex" justifyContent="space-between" width="100%">
            <Box
              display="flex"
              justifyContent="space-between"
              alignItems=" stretch"
              width="100%"
              flexWrap={"wrap"}
            >
              <Button
                variant={lastView === "month" ? "contained" : "outlined"}
                color={"secondary"}
                disableElevation
                className={clases.buttonTab}
                size="small"
                onClick={() => onViewChange("month")}
              >
                Mes
              </Button>
              <Button
                variant={lastView === "week" ? "contained" : "outlined"}
                color={"secondary"}
                disableElevation
                className={clases.buttonTab}
                size="small"
                onClick={() => onViewChange("week")}
              >
                Semana
              </Button>
              <Button
                variant={lastView === "day" ? "contained" : "outlined"}
                color={"secondary"}
                disableElevation
                className={clases.buttonTab}
                size="small"
                onClick={() => onViewChange("day")}
              >
                Día
              </Button>
              <Button
                variant={lastView === "agenda" ? "contained" : "outlined"}
                color={"secondary"}
                disableElevation
                className={clases.buttonTab}
                size="small"
                onClick={() => onViewChange("agenda")}
              >
                Agenda
              </Button>{" "}
              <Button
                variant={eliminado ? "contained" : "outlined"}
                color={"error"}
                disableElevation
                className={clases.buttonTab}
                size="small"
                onClick={async () => {
                  setcargando(true);
                  const fullBRes = defaultFullBuscador();
                  setfullBuscador(fullBRes);
                  await remplazarRowsState(
                    rowsPerPage,
                    0,
                    "",
                    input.id,
                    orden,
                    JSON.stringify(fullBRes),
                    rucempresa,
                    !eliminado
                  );

                  setPage(0);
                  setsearch("");
                  seteliminado(!eliminado);
                  setbuscando(false);
                  setcargando(false);
                  setopenMB(false);
                }}
              >
                Eliminados
              </Button>
            </Box>
          </Box>
          <Divider />
          <ModalB
            defaultFullBuscador={defaultFullBuscador}
            openMB={openMB}
            setopenMB={setopenMB}
            titulo={"Busqueda avanzada (Reservas)"}
            fullBuscador={fullBuscador}
            setfullBuscador={setfullBuscador}
            arrayProps={columns}
            handleChange={handleChange}
            funcionB={funcionB}
            buscando={buscando}
            funcionDefault={funcionDefault}
          />
          <Tabla
            page={page}
            setPage={setPage}
            rowsPerPage={rowsPerPage}
            setRowsPerPage={setRowsPerPage}
            search={search}
            input={input}
            orden={orden}
            setinput={setinput}
            setorden={setorden}
            open={open}
            setopen={setopen}
            buscando={buscando}
            cargando={cargando}
            setcargando={setcargando}
            fullBuscador={fullBuscador}
            //  STATE
            rows={rows}
            columns={columns}
            ObjectoActivo={ObjectoActivo}
            pagination={pagination}
            // FUNCIONES STATE
            getDataState={getDataState}
            obtenerState={obtenerState}
            eliminado={eliminado}
            addRowsState={addRowsState}
            remplazarRowsState={remplazarRowsState}
            agregarState={agregarState}
            editarState={editarState}
            eliminarState={eliminarState}
            eliminarSeleccionState={eliminarSeleccionState}
            changeObjetoActivoState={changeObjetoActivoState}
            rowsImportState={rowsImportState}
            arrayExport={arrayExport}
            setarrayExport={setarrayExport}
            puedeExportar={puedeExportar}
            desdeOtroLugarMascota={desdeOtroLugarMascota}
            seteditar={seteditar}
            socket={socket}
            // PROPIETARIO
            changeObjetoActivoStatePropietario={
              changeObjetoActivoStatePropietario
            }
            setopenPropietarioModal={setopenPropietarioModal}
          />
        </>
      ) : (
        <Calendar
          toolbar={!cargando}
          culture="es"
          localizer={localizer}
          events={dataCalendar}
          defaultView={lastView}
          startAccessor="start"
          endAccessor="end"
          style={{ height: desdeDashboard ? "60vh" : "80vh" }}
          messages={getMessages()}
          eventPropGetter={eventStyleGetter}
          components={{
            event: (event) => (
              <CalendarEvent
                event={event}
                changeObjetoActivoStatePropietario={
                  changeObjetoActivoStatePropietario
                }
                setopenPropietarioModal={setopenPropietarioModal}
                setopenConfirmDialog={setopenConfirmDialog}
              />
            ),
          }}
          onDoubleClickEvent={onDoubleClick}
          onSelectEvent={onSelect}
          onView={onViewChange}
          onRangeChange={async (fechas) => {
            setcargando(true);
            let url = `reservas/toCalendar?tipo=${
              fechas.length === 1 ? "day" : "month"
            }&fechas=${JSON.stringify(fechas)}&eliminado=${eliminado}`;
            if (fechas.length === 7) {
              url = `reservas/toCalendar?tipo=${"week"}&fechas=${JSON.stringify(
                [...fechas, addDays(fechas[6], 1)]
              )}&eliminado=${eliminado}`;
            }
            const res = await clienteAxios.get(url);

            setdataCalendar([
              ...res.data.data.map((reserva) => ({
                ...reserva,
                start: new Date(reserva.resFecha),
                end: new Date(reserva.resFechaFinal),
              })),
            ]);
            setcargando(false);
          }}
        />
      )}
    </Box>
  );
};

export default memo(Reservas);
