import React, { useCallback, useEffect, useState } from "react";
import clienteAxios from "../../config/axios";
import {
  Card,
  CardContent,
  Typography,
  Grid,
  Box,
  Button,
  Divider,
} from "@mui/material";
import {
  NavLink,
  useLocation,
} from "react-router-dom/cjs/react-router-dom.min";
import ChatPage from "./ChatPage";
import queryString from "query-string";
import { SendMessage } from "./Components";
import { BoxChat, TypographyChatActive } from "./style";
import { v4 } from "uuid";
const Asistente_Virtual = () => {
  const [conversations, setConversations] = useState([]);
  const location = useLocation();
  const getConversations = async () => {
    const res = await clienteAxios.get(
      "/asistente_virtual/historial/conversaciones"
    );
    console.log({ res });

    setConversations(res.data);
  };

  const { id = "" } = queryString.parse(location.search);

  useEffect(() => {
    getConversations();
  }, []);
  useEffect(() => {
    console.log(id);
  }, [id]);
  const handleSendMessage = useCallback(async (id, query, setMensajes) => {
    if (setMensajes) {
      setMensajes((prev) => [
        ...prev,
        {
          contenido: query,
          fecha: Date.now(),
          id: v4(),
          sesion_id: id,
          tipo_mensaje: "pregunta",
        },
      ]);
    }
    // const clienteAxios = await clienteAxios.post(
    //   "asistente_virtual/conversaciones",
    //   {
    //     sesion_id: id,
    //     query: query,
    //   }
    // );
  }, []);
  if (id) {
    return <ChatPage handleSendMessage={handleSendMessage} />;
  }
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
        width: "100%",
        height: "80vh",
        ".swal2-container": {
          zIndex: 1301,
        },
        ".swal2-popup": {
          background: "red",
        },
      }}
    >
      <Box>
        <Divider
          sx={{
            display: "flex",

            alignItems: "center",
          }}
        >
          <TypographyChatActive
            variant="subtitle2"
            color="primary"
            textAlign={"center"}
            fontWeight={"700"}
          >
            Historial de conversaciones
          </TypographyChatActive>
        </Divider>
      </Box>
      <BoxChat id="mensajes">
        <Grid container spacing={3} sx={{ flexGrow: 1 }}>
          {/* {[
            ...conversations,
            ...conversations,
            ...conversations,
            ...conversations,
          ].map((conversation) => ( */}
          {conversations.map((conversation) => (
            <Grid item xs={12} sm={6} md={4} key={conversation.sesion_id}>
              <NavLink
                to={`/Contable/Asistente_Virtual?id=${conversation.sesion_id}&titulo=${conversation.titulo}`}
                style={{ textDecoration: "none" }}
              >
                <Card
                  sx={{
                    transitionProperty: "opacity",
                    transitionDuration: "0.3s",
                    opacity: 0.7,
                    ":hover": {
                      cursor: "pointer",
                      opacity: 1,
                    },
                  }}
                >
                  <CardContent>
                    <Typography variant="h6" component="div">
                      {conversation.titulo}
                    </Typography>
                    <Typography color="textSecondary">
                      Fecha:
                      {new Date(conversation.fecha_inicio).toLocaleString()}
                    </Typography>
                  </CardContent>
                </Card>
              </NavLink>
            </Grid>
          ))}
        </Grid>
      </BoxChat>
      <SendMessage
        id={0}
        handleSendMessage={handleSendMessage}
        setMensajes={() => {}}
      />
    </Box>
  );
};

export default Asistente_Virtual;

// <Box
// sx={{
//   display: "flex",
//   flexDirection: "column",
//   justifyContent: "space-between",
//   width: "100%",
//   height: "80vh",
//   ".swal2-container": {
//     zIndex: 1301,
//   },
//   ".swal2-popup": {
//     background: "red",
//   },
// }}
// >
// <Box>
//   <Divider
//     sx={{
//       display: "flex",

//       alignItems: "center",
//     }}
//   >
//     <TypographyChatActive
//       variant="subtitle2"
//       color="primary"
//       textAlign={"center"}
//       fontWeight={"700"}
//     >
//       Historial de Conversaciones
//     </TypographyChatActive>
//     {cargando && <Typography variant="overline">CARGANDO...</Typography>}
//   </Divider>
// </Box>

// </Box>
